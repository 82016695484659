
import { computed, defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { saveOrganization, saveRehab } from "@/core/services/JwtService";

export interface Data {
  rehabs: any[];
}

export default defineComponent({
  name: "OrganizationsListPage",
  components: {},
  setup() {
    const store = useStore();

    const organizations = computed(() => {
      return store.getters.allOrganizations;
    });

    let data = reactive<Data>({
      rehabs: [],
    });

    async function selectOrganization(org) {
      saveOrganization(org.id);
      store.commit("SETORGANIZATION", org);
      ApiService.setTenant();
      selectRehab(null);

      await store.dispatch("GETALLREHABS");
      data.rehabs = store.getters.allRehabs;
    }

    function selectRehab(rehab) {
      saveRehab(rehab?.rehabId);
      store.commit("SETCURRENTREHaB", rehab);
      ApiService.setRehab();
    }

    onMounted(async () => {
      setCurrentPageTitle("Organizations List");
    });

    return {
      organizations,
      selectOrganization,
      selectRehab,
      data,
    };
  },
});
